import React from "react";

const Features_invu = () => {
  return (
    <>
      <section className="bg-pirBlack py-16">
        <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="max-w-xl mb-12 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="mt-16 inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                Trabaja mejor
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="#fff"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-white lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="d0d83814-78b6-480f-9a5f-7f637616b267"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">Elija el</span>
              </span>{" "}
              mejor sistema de punto de venta para su empresa
            </h2>
          </div>
        </div>
        <div className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-pirBlack md:px-24 lg:px-8 ">
          <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
            <div className="lg:py-6 lg:pr-16">
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border  bg-gradient-to-r  from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                        <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">
                    Existencias
                  </p>
                  <p className="text-gray-400">
                    <span className="font-semibold">
                      Inventario, residuos, compras.
                    </span>{" "}
                    Todo esto no ocurre por casualidad, requiere planificación
                    en los procesos y una comunicación abierta.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                        <path
                          fill-rule="evenodd"
                          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">Pagos</p>
                  <p className="text-gray-400">
                    <span className="font-semibold">
                      Acepte pagos rápidamente.
                    </span>{" "}
                    Tanto si vende en persona, en línea o sobre la marcha, Invu
                    puede ayudarle a procesar casi cualquier tipo de pago.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">Clientes</p>
                  <p className="text-gray-400">
                    <span className="font-semibold">
                      Marketing, redes sociales, fidelización.
                    </span>{" "}
                    Haga crecer su negocio, integre sus datos y construya
                    relaciones duraderas con sus clientes. relaciones duraderas
                    con los clientes, todo gratis.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13 7H7v6h6V7z" />
                        <path
                          fill-rule="evenodd"
                          d="M7 2a1 1 0 012 0v1h2V2a1 1 0 112 0v1h2a2 2 0 012 2v2h1a1 1 0 110 2h-1v2h1a1 1 0 110 2h-1v2a2 2 0 01-2 2h-2v1a1 1 0 11-2 0v-1H9v1a1 1 0 11-2 0v-1H5a2 2 0 01-2-2v-2H2a1 1 0 110-2h1V9H2a1 1 0 010-2h1V5a2 2 0 012-2h2V2zM5 5h10v10H5V5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">
                    Tecnología
                  </p>
                  <p className="text-gray-400">
                    <span className="font-semibold">
                      Hardware, aplicaciones móviles y de escritorio.
                    </span>
                    Fácil de usar para todo tipo de negocios, este POS se puede
                    configurar para hacer justo lo que su negocio necesita.
                  </p>
                </div>
              </div>
              <div className="flex ">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        className="w-6 text-white"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <polyline
                          fill="none"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          points="6,12 10,16 18,8"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="pt-1">
                  <p className="mb-2 text-lg font-bold text-white">Éxito</p>
                </div>
              </div>
            </div>
            <div className="relative">
              <img
                className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
                src="https://img.invupos.com/webinvu/About%20us%20module%20/about%20us%20section%201.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features_invu;
