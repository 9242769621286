import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal_started from "../Global_components/Modal_started";
import { Link } from "react-scroll";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section class="relative w-full bg-[#030303]">
        <div class="">
          <div class="max-w-screen-xl px-4 py-28 mx-auto sm:px-6 lg:px-16">
            <div class="flex items-center flex-wrap gap-12 px-2 md:px-0 -mt-12">
              <div class="lg:w-6/12 lg:-ml-7">
                <Carousel
                  className="z-10 relative"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={5000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div class="relative">
                    <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-40"></div>
                    <img
                      src="https://img.invupos.com/webinvu/About%20us%20module%20/about%20us%20hero%201.jpg"
                      alt=""
                      loading="lazy"
                      className="rounded-xl"
                    />
                  </div>
                  <div class="relative">
                    <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-40"></div>
                    <img
                      src="https://img.invupos.com/webinvu/About%20us%20module%20/about%20us%20hero%202.jpg"
                      alt=""
                      loading="lazy"
                      className="rounded-xl"
                    />
                  </div>
                  <div class="relative">
                    <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-40"></div>
                    <img
                      src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652453546/Branding%20Invu%20/Hero%20/Downloader.la_-627e7095ccbcb_xqheyx.jpg"
                      alt=""
                      loading="lazy"
                      className="rounded-xl"
                    />
                  </div>
                </Carousel>
              </div>

              <div class="relative z-20 lg:w-6/12 lg:-ml-20">
                <h1 class="font-bold text-white text-[40px] sm:text-6xl md:text-7xl xl:text-[66px]">
                  Empieza a Facturar Electrónicamente con{" "}
                  <span className="bg-gradient-clip">Invu</span>.
                </h1>
                <div class="mt-16 space-y-8 lg:ml-32">
                  <p class="text-gray-400 max-w-2xl">
                    Desde realizar el trámite con la DGI hasta entregarte los
                    equipos que necesitas, INVU es la única solución que
                    necesitas. Enfócate en crecer tu negocio y cumple con el
                    DGI sin complicaciones.
                  </p>
                  <div class="flex space-x-4 ">
                    <button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      class="inline-block px-8 py-3 text-sm font-medium text-pirBlack bg-white border border-white rounded active:text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring"
                    >
                      Comenzar
                    </button>

                    <Link
                      to="history"
                      activeClass="active"
                      spy={true}
                      smooth={true}
                    >
                      <a className="cursor-pointer inline-block px-8 py-3 text-sm font-medium text-white border border-white rounded hover:bg-white hover:text-pirBlack  focus:outline-none focus:ring">
                        Leer más
                      </a>
                    </Link>
                  </div>
                </div>
                '
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
