import React, { useState } from "react";
import Modal_started from "../Global_components/Modal_started";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-sm lg:text-base justify-start max-w-lg text-left md:text-sm text-gray-100 font-semibold">
          {title}
        </p>
        <div className="flex items-center justify-center w-8 h-8 border bg-white rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-pirBlack transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm lg:text-base justify-start md:text-sm text-gray-400">
            {children}
          </p>
        </div>
      )}
    </div>
  );
};

const FAQ_electronic_billing = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="bg-pirBlack py-16">
        <div className=" max-w-1xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"></div>
        <div class="flex flex-wrap overflow-hidden max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2">
            <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
              &mdash; Soporte
            </p>
            <h2 className="text-4xl font font-extrabold tracking-tight text-white sm:text-3xl">
              Preguntas frecuentes sobre Facturación Electrónica con Invu POS
            </h2>
            <p class="mt-4 leading-relaxed text-gray-400">
              La facturación electrónica es una herramienta esencial para
              cualquier empresa que desee llevar un registro eficiente de sus
              transacciones comerciales y cumplir con las normativas fiscales.{" "}
              {""}
            </p>
          </div>
        </div>
        <div className="max-w-screen-xl -mt-6 px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 row-gap-8 lg:grid-cols-2">
            <div className="space-y-4">
              <div>
                <Item title="¿Es obligatoria la facturación electrónica en Panamá?">
                  Sí, la facturación electrónica es obligatoria en Panamá para
                  la mayoría de los negocios.
                </Item>
              </div>
              <div>
                <Item title="¿INVU POS está autorizado por la DGI para emitir facturas electrónicas?">
                  INVU POS está integrado con diferentes PACs (Proveedores
                  Autorizados Calificados) autorizados por la Dirección General
                  de Ingresos (DGI) para emitir facturas electrónicas en Panamá.
                </Item>
              </div>
              <div>
                <Item title="¿Qué sucede si tengo problemas técnicos con mi factura electrónica emitida con INVU POS?">
                  Si se experimentan problemas técnicos con la factura
                  electrónica emitida con INVU POS, se puede contactar al
                  soporte técnico de INVU POS para recibir asistencia.
                </Item>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Item title="¿Cómo puedo hacer el trámite para obtener una firma electrónica?">
                  Se puede obtener una firma electrónica llenando nuestros
                  formularios directamente con nosotros.
                </Item>
              </div>
              <div>
                <Item title="¿Qué ventajas ofrece la facturación electrónica en comparación con la facturación tradicional?">
                  La facturación electrónica ofrece ventajas como mayor
                  eficiencia y ahorro de tiempo y costos, reducción del uso de
                  papel, facilidad para el almacenamiento y recuperación de
                  facturas, y menor riesgo de pérdidas.
                </Item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ_electronic_billing;
