import React, { useState } from "react";
import Modal_started from "../Global_components/Modal_started";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-sm lg:text-base justify-start text-left md:text-sm text-gray-100 font-semibold">
          {title}
        </p>
        <div className="flex items-center justify-center w-8 h-8 border bg-white rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-pirBlack transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm lg:text-base justify-start md:text-sm text-gray-400">
            {children}
          </p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="bg-pirBlack py-16">
        <div className=" max-w-1xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"></div>
        <div class="flex flex-wrap overflow-hidden max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2">
            <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
              &mdash; Soporte
            </p>

            <h2 className="text-4xl font font-extrabold tracking-tight text-white sm:text-3xl">
              Preguntas frecuentes sobre Invu POS
            </h2>
            <p class="mt-4 leading-relaxed text-gray-400">
              Todo lo que necesita saber sobre el producto y la facturación. ¿No
              encuentra la respuesta que busca? Chatea con nuestro amable
              equipo. {""}
              <span
                onClick={() => {
                  setIsOpen(true);
                }}
                className="font-semibold underline cursor-pointer"
              >
                Chatea con nuestro amable equipo.
              </span>
            </p>
          </div>
        </div>
        <div className="max-w-screen-xl -mt-6 px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 row-gap-8 lg:grid-cols-2">
            <div className="space-y-4">
              <div>
                <Item title="¿Qué es INVU POS?">
                  INVU POS es un sistema de punto de venta diseñado para ayudar
                  a los negocios a gestionar las ventas y las operaciones del
                  negocio en un solo lugar.
                </Item>
              </div>
              <div>
                <Item title="¿Cuánto cuesta INVU POS?">
                  El costo de INVU POS depende del tamaño y las necesidades
                  específicas del egocio. Se puede obtener una cotización
                  personalizada a través del{" "}
                  <a
                    className="underline text-white font-bold"
                    href="https://invupos.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    sitio web de INVU.
                  </a>
                </Item>
              </div>
              <div>
                <Item title="¿Qué soporte técnico ofrece INVU POS?">
                  INVU POS ofrece soporte técnico las 24 horas del día, los 7
                  días de la semana, a través de chat en línea, correo
                  electrónico y teléfono.
                </Item>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Item title="¿Cuáles son las características principales de INVU POS?">
                  Las características principales de INVU POS incluyen gestión
                  de pedidos y pagos, gestión de inventario, integración con
                  otros sistemas y soporte técnico.
                </Item>
              </div>
              <div>
                <Item title="¿Puedo integrar INVU POS con otros sistemas de mi negocio?">
                  Sí, INVU POS se puede integrar con otros sistemas de negocio
                  para ayudar a gestionar todas las operaciones en un solo
                  lugar.
                </Item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
