import React from "react";

const services = [
  {
    id: 1,
    name: "Lealtad",
    description:
      "Construya su base de clientes, haga crecer su negocio habitual y aumente su clientela",
    imgSrc:
      "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    href: "/productos/lealtad",
  },
  {
    id: 2,
    name: "Marketing",
    description:
      "Facilitamos la comunicación entre fabricante, distribuidor y consumidor en el punto de venta",
    imgSrc:
      "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    href: "/productos/marketing",
  },
  {
    id: 3,
    name: "Tarjetas de regalo",
    description:
      "Impulse el flujo de caja inmediato y la repetición del negocio con tarjetas regalo físicas y digitales",
    imgSrc:
      "https://images.unsplash.com/photo-1521685468847-de0a1a3c41a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    href: "/expertos-de-invu",
  },
  {
    id: 4,
    name: "Exposición en cocina",
    description:
      "Mejorar la precisión de los pedidos, aumentar la eficacia y cronometrar perfectamente las comidas y los platos",
    imgSrc: "https://img.invupos.com/webinvu/About%20us%20module%20/KDS.jpg",
    href: "/productos/kds",
  },
  {
    id: 5,
    name: "Pagos",
    description:
      "Consiga tranquilidad con una solución de pagos que ofrece precios transparentes. ",
    imgSrc:
      "https://img.invupos.com/webinvu/About%20us%20module%20/payments.jpg",
    href: "/pagos/general",
  },
  {
    id: 6,
    name: "E-commerce",
    description:
      "Amplíe su negocio a todo el mundo, venda a quien quiera, donde quiera y cuando quiera",
    imgSrc:
      "https://img.invupos.com/webinvu/About%20us%20module%20/ecommerce.jpg",
    href: "/productos/comercio-electronico",
  },
  {
    id: 7,
    name: "Punto de venta",
    description:
      "Disfrute de tener su contabilidad al día con nuestras integraciones Invu",
    imgSrc:
      "https://img.invupos.com/webinvu/About%20us%20module%20/point%20of%20sale.jpg",
    href: "/productos/punto-de-venta",
  },
  {
    id: 8,
    name: "Plus",
    description:
      "Pagos sin contacto, agilice sus pedidos y mantenga el servicio a pleno rendimiento.",
    imgSrc: "https://img.invupos.com/webinvu/About%20us%20module%20/plus.jpg",
    href: "/productos/plus",
  },
];

const Our_products = () => {
  return (
    <>
      <div class="py-16 bg-pirBlack ">
        <div class="max-w-screen-xl px-4 py-28 mx-auto sm:px-6 lg:px-16">
          <div className="max-w-xl mb-12 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 -mt-12">
            <div>
              <p className="mt-16 inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                Nuestros productos
              </p>
            </div>
            <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="#fff"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-white lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="d0d83814-78b6-480f-9a5f-7f637616b267"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">Elija</span>
              </span>{" "}
              una gama de productos complementarios para su negocio
            </h2>
          </div>

          <div class="grid gap-6  sm:grid-cols-2 lg:grid-cols-4">
            {services.map((service) => (
              <a>
                <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
                  <div class="relative">
                    <div
                      aria-hidden="true"
                      class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                    ></div>
                    <img
                      src={service.imgSrc}
                      alt="art cover"
                      loading="lazy"
                      width="1000"
                      height="667"
                      class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                    />
                  </div>
                  <div class="relative space-y-4 -mt-20 p-4">
                    <h4 class="text-xl font-semibold text-white">
                      {service.name}
                    </h4>
                    <p class="text-gray-400">{service.description}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Our_products;
