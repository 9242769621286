import React from "react";

const Navbar = () => {
  return (
    <>
      <div id="navbar" class="bg-pirBlack">
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div class="flex justify-start text-teal-600 sm:justify-start">
              <img
                className="h-14 w-auto"
                src="https://img.invupos.com/webinvu/Footer/Logo%20/Logo_blanco_invu_lgzbkn.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
