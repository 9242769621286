import React, { useState } from "react";
import Modal_started from "../Global_components/Modal_started";

const Pricing_plans = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="bg-pirBlack">
        <div class="">
          <div class="container m-auto px-8 py-20 md:px-12 lg:px-20">
            <div class="mt-12 m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 md:-space-x-4 xl:w-10/12">
              <div class="relative z-10 -mx-4 group md:w-6/12 md:mx-0 lg:w-5/12">
                <div
                  aria-hidden="true"
                  class="absolute top-0 w-full h-full rounded-2xl bg-white shadow-xl transition duration-500 group-hover:scale-105 lg:group-hover:scale-110"
                ></div>
                <div class="relative p-6 space-y-6 lg:p-8">
                  <h3 class="text-3xl text-gray-700 font-semibold text-center">
                    Oferta especial
                  </h3>
                  <div>
                    <div class="relative flex justify-around">
                      <div class="flex items-end">
                        <span class="text-8xl text-gray-800 font-bold leading-0">
                          35
                        </span>
                        <div class="pb-2">
                          <span class="block text-2xl text-gray-700 font-bold">
                            %
                          </span>
                          <span class="block text-xl text-indigo-600 font-bold">
                            Menos
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    role="list"
                    class="w-max space-y-4 py-6 m-auto text-gray-600"
                  >
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>Pedidos en línea</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>Reserva de citas</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>Modo invitado (modo quiosco)</span>
                    </li>
                  </ul>
                  <p class="lg:flex items-center justify-center space-x-4 text-lg text-gray-600 text-center">
                    <span>Llámanos al</span>
                    <a
                      href="tel:+24300"
                      class="flex space-x-2 items-center justify-center text-indigo-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="w-6"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                      <span class="font-semibold">+507 201-8490</span>
                    </a>
                    <span>or</span>
                  </p>
                  <button class="block w-full py-3 px-6 text-center rounded transition bg-indigo-600 hover:bg-indigo-600  focus:bg-indigo-600">
                    <button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      class="text-white font-semibold"
                    >
                      Contacto con ventas
                    </button>
                  </button>
                </div>
              </div>

              <div class="relative group md:w-6/12 lg:w-7/12">
                <div
                  aria-hidden="true"
                  class="absolute top-0 w-full h-full rounded-2xl bg-white shadow-lg transition duration-500 group-hover:scale-105"
                ></div>
                <div class="relative p-6 pt-16 md:p-8 md:pl-12 md:rounded-r-2xl lg:pl-20 lg:p-16">
                  <ul role="list" class="space-y-4 py-6 text-gray-600">
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>Gestión de empleados y permisos</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>Seguimiento de inventarios y alertas</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>Productos con variaciones y modificadores</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>
                        Integración con impresoras y cajones portamonedas
                      </span>
                    </li>
                  </ul>
                  <p class="text-gray-500 text-sm">
                    El equipo puede ser de cualquier tamaño, y puede añadir o
                    cambiar miembros según sea necesario. sea necesario.
                    Empresas que utilizan nuestra plataforma:
                  </p>
                  <div class="mt-6 flex justify-between gap-6">
                    <img
                      class="w-16 lg:w-24"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/Little_caesars_e0f0gu.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-6 lg:w-20"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/cinnabon_1_fqrx8o.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-8 lg:w-20"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/KrispyKreme_1_fqcaqz.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-20 lg:w-28"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/YogenFruz_1_1_tph8r7.svg"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-20 mx-auto sm:px-6 lg:px-20 ">
          <div className="lg:flex items-center justify-between">
            <div className=" lg:w-1/2 w-full">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
                Precios
              </h2>
              <h1
                role="heading"
                className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-white"
              >
                Nuestros planes y precios
              </h1>
              <p
                role="contentinfo"
                className="text-base leading-5 mt-5 text-gray-400"
              >
                Estamos trabajando en un conjunto de herramientas para facilitar
                la gestión de su negocio a través de un sistema de punto de
                venta. estamos deseando conocer su opinión.
              </p>
              <div className="mt-6">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="inline-block px-8 py-3 text-sm font-medium text-pirBlack bg-white border border-white rounded active:text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring"
                >
                  Comenzar
                </button>
              </div>
            </div>
            <div
              className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8"
              role="list"
            >
              <img
                src="https://i.ibb.co/0n6DSS3/bgimg.png"
                className="absolute w-full -ml-12 mt-24"
                alt="background circle images"
              />
              <div
                role="listitem"
                className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30"
              >
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    Invu POS
                  </h2>
                  <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800">
                    $75
                    <span className="font-normal text-base"> / Mensuales</span>
                  </p>
                </div>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  Un punto de venta completo para cualquier concepto.
                </p>
              </div>
              <div
                role="listitem"
                className="bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30"
              >
                <div className="w-2.5  h-auto bg-indigo-700 rounded-tl-md rounded-bl-md" />
                <div className="w-full p-8">
                  <div className="md:flex items-center justify-between">
                    <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                      E-Commerce
                    </h2>
                    <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-indigo-600">
                      $49
                      <span className="font-normal text-base">
                        {" "}
                        / Mensuales
                      </span>
                    </p>
                  </div>
                  <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                    E-commerce todo en uno integrado. Empiece a vender en
                    cuestión de minutos
                  </p>
                </div>
              </div>
              <div
                role="listitem"
                className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30 mt-7"
              >
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    Loyalty
                  </h2>
                  <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800">
                    $49
                    <span className="font-normal text-base"> / Mensuales</span>
                  </p>
                </div>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  Plataforma de recompensas digitales. Premia a tus clientes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing_plans;
