import React from "react";
import Footer from "../components/Global_components/Footer";
import Navbar from "../components/Global_components/Navbar";
import Announcement from "../components/Home/Announcement";
import CTA from "../components/Home/CTA";
import FAQ from "../components/Home/FAQ";
import FAQ_electronic_billing from "../components/Home/FAQ_electronic_billing";
import Features_invu from "../components/Home/Features_invu";
import Get_started from "../components/Home/Get_started";
import Hero from "../components/Home/Hero";
import History from "../components/Home/History";

import Our_products from "../components/Home/Our_products";
import Pricing_plans from "../components/Home/Pricing_plans";

const Landing = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <History />
      <Features_invu />
      <FAQ />
      <CTA />
      <FAQ_electronic_billing />
      <Our_products />
      <Pricing_plans />
      <Get_started />
      <Footer />
    </>
  );
};

export default Landing;
