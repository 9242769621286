import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const Modal_started = ({ setIsOpen }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/7060f71e-0699-4e54-b783-f64a9af03baf";

    var data = {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },

        {
          name: "mobilephone",
          value: phone,
        },

        {
          name: "company",
          value: company,
        },
        {
          name: "message",
          value: message,
        },
      ],
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-green-600">
                      Message sent.
                    </span>{" "}
                    We will communicate soon 📭
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
        // alert(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        console.log(xhr.responseText);
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };
    // Sends the request
    xhr.send(final_data);
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setCompany("");
  };

  return (
    <>
      <div class="flex justify-center flex overflow-x-hidden overflow-y-auto z-50 inset-0 fixed h-screen items-center  antialiased bg-gray-500 bg-opacity-75 transition-opacity ">
        <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
          <form onSubmit={handleSubmit}>
            <div class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-gray-800">Hablar con ventas</p>
              <button onClick={() => setIsOpen(false)}>
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="flex flex-col px-6 py-5  bg-gray-50">
              <a href="">
                <img
                  className="h-12 w-auto"
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                  alt=""
                />
              </a>

              <div class="flex flex-col mt-6 sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      First name
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder="Nombre"
                      required
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                    <label class="sr-only" for="firstname">
                      Last name
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder="Apellido"
                      required
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      Email
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder="Correo Electrónico"
                      required
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                    <label class="sr-only" for="firstname">
                      Phone
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder="Número de Teléfono"
                      required
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label class="sr-only" for="firstname">
                  Company
                </label>
                <input
                  class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                  placeholder="Nombre del negocio"
                  required
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>

              <textarea
                placeholder="Mensaje"
                class="p-4  bg-white border border-gray-200 rounded shadow-sm h-36"
                required
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>

            <div class="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
              <p
                onClick={() => setIsOpen(false)}
                class="font-semibold text-gray-600 cursor-pointer"
              >
                Cancelar
              </p>
              <button
                type="submit"
                value="Submit"
                className="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium">Solicitar demo</span>
              </button>
            </div>
          </form>
          <Toaster position="bottom-center" reverseOrder={false} />
        </div>
      </div>
    </>
  );
};

export default Modal_started;
