import React, { useState } from "react";
import Modal_started from "../Global_components/Modal_started";

const CTA = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div class="py-16 bg-pirDarkGray">
        <div class="container m-auto px-6 space-y-8 text-gray-500 md:px-12 lg:px-20">
          <div class="flex items-center justify-center -space-x-2">
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.pexels.com/photos/3760953/pexels-photo-3760953.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="member photo"
              class="w-8 h-8 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt="member photo"
              class="w-12 h-12 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
              alt="member photo"
              class="z-10 w-16 h-16 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt="member photo"
              class="relative w-12 h-12 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.pexels.com/photos/3760953/pexels-photo-3760953.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="member photo"
              class="w-8 h-8 object-cover rounded-full"
            />
          </div>
          <div class="m-auto space-y-6 md:w-8/12 lg:w-7/12">
            <h1 class="text-4xl text-white font-bold text-center md:text-5xl">
              Empiece ahora
            </h1>
            <p class="text-xl text-center text-gray-400">
              ¿Está listo para ver cómo Invu puede transformar su negocio?
              Hablemos
            </p>
            <div class="flex flex-wrap justify-center gap-6">
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                class="inline-block px-8 py-3 text-sm font-medium text-pirBlack bg-white border border-white rounded active:text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring"
              >
                Contacto con ventas
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTA;
